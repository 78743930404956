// Fonts
@font-face {
    font-family: 'Yanone Kaffeesatz';
    src: url(../../assets/fonts/YanoneKaffeesatz/YanoneKaffeesatz-VariableFont_wght.ttf) format('truetype');
}

// Variables
$breezyPrimary: #393b61;
$breezySecondary: #ca705d;
$breezyTextLight: #ffffff;
$breezyTextDark: #2C2C2C;
$breezyFont: 'Yanone Kaffeesatz', sans-serif;

// Styles
.breezy,
.breezyboystrip {

    // Base
    @include brand (
        $breezyPrimary,
        $breezySecondary,
        $breezyFont,
        $breezyTextLight,
        $breezyTextLight
    );

    // Overrides

}